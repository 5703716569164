import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "shards-react";
import {
  Backdrop,
  CircularProgress,
  MenuItem,
  TextField,
  Avatar,
  SvgIcon,
  IconButton,
} from "@material-ui/core";
import Tarjetas from "./Tarjetas";
import request from "../../../requests/request";
import {
  postVenta,
  readComprobantes,
  readDescuentos,
  readMedioPago,
  sendMailVenta,
} from "../../../requests/urls";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewMedio,
  clearPresupuesto,
  deleteMedioPago,
  updateComprobante,
  updateDescuento,
  updateListaImpuestos,
  updateMedioPago,
  updateMontoMedio,
  updateMultipleMedioPago,
  updatePriceChangeMedio,
} from "../../../Redux/Actions/presupuestoActions";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import {
  getEstadoCliente,
  isFacturaM,
  valuesPostVenta,
  getDataTarjeta,
  medioPagoSinDefinir,
} from "./utils";
import ModalPagosMultiples from "./ModalPagosMultiples";
import {
  errorNotification,
  successNotification,
  warningNotification,
} from "../../../components/Notifications";
import ModalVenta from "../../Venta/ModalVenta/ModalVenta";
import { calculoMontosVentas, getVenta } from "../../Venta/utils";
import TransferenciaBancaria from "./TransferenciaBancaria";
import Cheque from "./Cheque/index";
import FormAutorizacionTarjeta from "./FormAutorizacionTarjeta";
import { useSearchEmpleado } from "../../../customHooks/useSearchEmpleado";
import { calcularComision } from "../Importes/utils";
import useArticlesToPDF from "../../../customHooks/useArticlesToPDF";
import { getPdfBlob } from "../../../components/ait-reusable/ModalWhatsApp/uploadPdf";
import Alert from "@material-ui/lab/Alert";
import ModalClienteMontoExcedido from "../../Venta/ModalClienteMontoExcedido";
import moment from "moment";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ModalInformacionFCEM from "../../../components/ait-reusable/ModalInformacionFCEM";
import useDisclosure from "../../../customHooks/useDisclosure";
import { formatoMoneda } from "../../../utils/parsers";
import HelpIcon from "@material-ui/icons/Help";
import ModalValidacionClientCAE from "../../../components/ait-reusable/ModalesErrorAFIP/ModalValidacionClientCAE";
import DeleteIcon from "@material-ui/icons/Delete";
import TooltipWithoutIcon from "../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Facturacion({
  idOrden,
  loadingPresupuesto,
  multiplePago,
  isPresupuesto,
  errorCheque,
  setErrorCheque,
  errorTransferencia,
  setErrorTransferencia,
  generatePdf,
  generatePdfConPrevisualizacion,
  vencimientoDelPago,
  setVencimientoDelPago,
  opcionTransferencia,
  setOpcionTransferencia,
  cbu,
  setCbu,
  alias,
  setAlias,
  isVenta,
  tiposVenta,
  tipoVentaSelected,
  setTipoVentaSelected,
  montoMaximo,
  dataWP,
  configCCC,
  datosOrdenMeli = null,
  setIdVentaErrorAFIP,
  setModalErrorAFIP,
  setModalClienteIncompleto = () => {},
}) {
  const [medioPagos, setMedioPagos] = useState([]);
  const [loadingComprobante, setLoadingComprobante] = useState(false);
  const [opcionesTransferencia, setOpcionesTransferencia] = useState([]);
  const [loadingDescuento, setLoadingDescuento] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [loadingMedioPago, setLoadingMedioPago] = useState(false);
  const [comprobantes, setComprobantes] = useState([]);
  const [descuentos, setDescuentos] = useState([]);
  const [modalPagosMul, setModalPagosMul] = useState(false);
  const [openModalVenta, setOpenModalVenta] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [mediosPagosChange, setMediosPagosChange] = useState(null);
  const [selectedMedioPago, setSelectedMedioPago] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalExcesoMontoCliente, setModalExcesoMontoCliente] = useState(false);
  const [modalErrorClienteCAE, setModalErrorClienteCAE] = useState(false);
  const { presupuesto, loginReducer: userData } = useSelector((state) => state);
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { medio_pago } = configGeneral;

  const estadoTributarioVendedor = localStorage.getItem("estadoTributario");
  const {
    cliente,
    tipoComprobante,
    medioPago,
    montos,
    detalles,
    descuento,
    isMultipleMedioPago,
  } = presupuesto;

  const dispatch = useDispatch();
  const { idPresupuesto, idRemito, idVenta } = useParams();
  const classes = useStyles();
  const { useEmpleado, useQueryEmpleado } = useSearchEmpleado();
  const {
    montoTotalConIvaEImpuestos,
    totalesPorImpuesto,
    mediosPagoProcesadosConMontosEImpuestos,
  } = calculoMontosVentas(presupuesto, true, null);
  const { articlesPDF } = useArticlesToPDF();
  const { pathname } = useLocation();
  const [openModalFCEM, { toggle: toggleModalFCEM }] = useDisclosure(false);

  const isClienteDescuento =
    isVenta &&
    cliente &&
    cliente.clienteDescuento.isClienteDescuento &&
    medioPago[0] &&
    medioPago[0].nombre === "Cuenta Corriente"
      ? true
      : false;

  const handleGetMedioPago = async () => {
    setLoadingMedioPago(true);
    try {
      const response = await request({
        method: "GET",
        url: readMedioPago,
      });
      handleResponseGetMedioPAgo(response.data);
      setLoadingMedioPago(false);
    } catch (error) {
      setLoadingMedioPago(false);
      console.error(error);
    }
  };

  const handleGetDescuentos = async () => {
    setLoadingDescuento(true);
    try {
      const response = await request({
        method: "GET",
        url: readDescuentos,
      });
      response.status !== 204 && setDescuentos(response.data);
      setLoadingDescuento(false);
    } catch (error) {
      console.error(error);
      setLoadingDescuento(false);
    }
  };

  const handleGetComprobantes = async () => {
    setLoadingComprobante(true);
    try {
      const response = await request({
        method: "GET",
        url: readComprobantes,
      });
      hanldeResponseGetComprobante(response);
      setLoadingComprobante(false);
    } catch (error) {
      console.error(error);
      setLoadingComprobante(false);
    }
  };

  const hanldeResponseGetComprobante = async (response) => {
    if (response.status !== 204) {
      let comprobantesGet = response.data.data.reverse();
      setComprobantes(comprobantesGet);
      if (!tipoComprobante) {
        let nombreComprobante = "";
        if (configGeneral.type_receipt === 1) {
          nombreComprobante = estadoTributarioVendedor === "RI" ? "B" : "C";
        } else {
          nombreComprobante = "Comprobante interno";
        }
        let idx = comprobantesGet.findIndex(
          (value) => value.nombre === nombreComprobante,
        );
        dispatch(updateComprobante(comprobantesGet[idx]));
      }
    }
  };

  const handleResponseGetMedioPAgo = (response) => {
    if (response.status !== 204) {
      let mediosPagoGet = response.data;
      setMedioPagos(mediosPagoGet);
    }
  };

  /**
   * @version 02.08.21 v2 
   * @author key
    * RI --> RI (A) 
      RI --> MO (A)
      RI --> EX (B)
      RI --> S/E (B)
      RI --> CF (B)

      MO --> RI (C)
      MO --> MO (C)
      MO --> EX (C)
      MO --> S/E (C)
      MO --> CF (C)
    */
  const filtradoComprobantes = () => {
    let array = [];
    if (estadoTributarioVendedor === "RI") {
      if (cliente && cliente.cliente) {
        if (cliente.cliente.comprobantePorDefecto === "INTERNO") {
          // Comprobante interno
          array = comprobantes.filter(
            (value) => value.nombre === "Comprobante interno",
          );
        } else if (cliente.cliente.comprobantePorDefecto === "AFIP") {
          // Comprobante AFIP
          switch (cliente.cliente.estadoTributario.nombre) {
            case "Responsable inscripto":
            case "RI":
              if (montoTotalConIvaEImpuestos >= userData.monto_minimo_fcem) {
                array = comprobantes.filter(
                  (value) =>
                    value.nombre === "A" ||
                    value.nombre === "FCEM A" ||
                    (configGeneral.es_m && value.nombre === "M"),
                );
              } else {
                array = comprobantes.filter(
                  (value) =>
                    value.nombre === "A" ||
                    (configGeneral.es_m && value.nombre === "M"),
                );
              }
              break;
            case "Monotributista":
            case "MO":
              if (montoTotalConIvaEImpuestos >= userData.monto_minimo_fcem) {
                array = comprobantes.filter(
                  (value) => value.nombre === "M" || value.nombre === "A",
                );
              } else {
                array = comprobantes.filter(
                  (value) =>
                    value.nombre === "A" ||
                    (configGeneral.es_m && value.nombre === "M"),
                );
              }
              break;
            case "Sin especificar":
            case "S/E":
            case "Exento":
            case "IVA no alcanzado":
            case "NA":
            case "EX":
            case "Consumidor final":
            case "CF":
              array = comprobantes.filter((value) => value.nombre === "B");
              break;
            default:
              break;
          }
        } else {
          // Comprobante ambos
          switch (cliente.cliente.estadoTributario.nombre) {
            case "Responsable inscripto":
            case "RI":
              if (montoTotalConIvaEImpuestos >= userData.monto_minimo_fcem) {
                array = comprobantes.filter(
                  (value) =>
                    value.nombre === "A" ||
                    value.nombre === "Comprobante interno" ||
                    value.nombre === "FCEM A" ||
                    (configGeneral.es_m && value.nombre === "M"),
                );
              } else {
                array = comprobantes.filter(
                  (value) =>
                    value.nombre === "A" ||
                    value.nombre === "Comprobante interno" ||
                    (configGeneral.es_m && value.nombre === "M"),
                );
              }
              break;
            case "Monotributista":
            case "MO":
              if (montoTotalConIvaEImpuestos >= userData.monto_minimo_fcem) {
                array = comprobantes.filter(
                  (value) =>
                    value.nombre === "M" ||
                    value.nombre === "A" ||
                    value.nombre === "Comprobante interno",
                );
              } else {
                array = comprobantes.filter(
                  (value) =>
                    value.nombre === "A" ||
                    value.nombre === "Comprobante interno" ||
                    (configGeneral.es_m && value.nombre === "M"),
                );
              }
              break;
            case "Sin especificar":
            case "S/E":
            case "Exento":
            case "IVA no alcanzado":
            case "NA":
            case "EX":
            case "Consumidor final":
            case "CF":
              array = comprobantes.filter(
                (value) =>
                  value.nombre === "Comprobante interno" ||
                  value.nombre === "B",
              );
              break;
            default:
              array = comprobantes.filter(
                (value) => value.nombre === "Comprobante interno",
              );
              break;
          }
        }
      } else if (idOrden) {
        array = comprobantes.filter(
          (value) =>
            value.nombre === "B" || value.nombre === "Comprobante interno",
        );
      } else {
        array = comprobantes.filter(
          (value) =>
            value.nombre === "Comprobante interno" || value.nombre === "B",
        );
      }
    } else if (
      estadoTributarioVendedor === "MO" ||
      estadoTributarioVendedor === "EX"
    ) {
      if (cliente && cliente.cliente) {
        if (cliente.cliente.comprobantePorDefecto === "INTERNO") {
          // Comprobante interno
          array = comprobantes.filter(
            (value) => value.nombre === "Comprobante interno",
          );
        } else if (cliente.cliente.comprobantePorDefecto === "AFIP") {
          // Comprobante AFIP
          switch (cliente.cliente.estadoTributario.nombre) {
            case "Responsable inscripto":
            case "RI":
            case "Monotributista":
            case "MO":
            case "Sin especificar":
            case "S/E":
            case "Exento":
            case "IVA no alcanzado":
            case "NA":
            case "EX":
            case "Consumidor final":
            case "CF":
              array = comprobantes.filter((value) => value.nombre === "C");
              break;
            default:
              break;
          }
        } else {
          // Comprobante ambos
          switch (cliente.cliente.estadoTributario.nombre) {
            case "Responsable inscripto":
            case "RI":
            case "Monotributista":
            case "MO":
            case "Sin especificar":
            case "S/E":
            case "Exento":
            case "IVA no alcanzado":
            case "NA":
            case "EX":
            case "Consumidor final":
            case "CF":
              array = comprobantes.filter(
                (value) =>
                  value.nombre === "Comprobante interno" ||
                  value.nombre === "C",
              );
              break;
            default:
              array = comprobantes.filter(
                (value) => value.nombre === "Comprobante interno",
              );
              break;
          }
        }
      } else if (idOrden) {
        array = comprobantes.filter(
          (value) =>
            value.nombre === "C" || value.nombre === "Comprobante interno",
        );
      } else {
        array = comprobantes.filter(
          (value) =>
            value.nombre === "Comprobante interno" || value.nombre === "C",
        );
      }
    } else {
      array = comprobantes.filter(
        (value) => value.nombre === "Comprobante interno",
      );
    }
    return array;
  };

  const filtradoMediosPagos = () => {
    let array = [];
    if (!cliente) {
      array = medioPagos.filter(
        (value) =>
          value.nombre !== "Cuenta Corriente" && value.nombre !== "Cheque",
      );
    } else {
      if (cliente.cuenta_corriente) {
        if (isVenta && configCCC && configCCC.dataConfig.bloquearCtaCte) {
          array = medioPagos.filter(
            (value) => value.nombre !== "Cuenta Corriente",
          );
        } else {
          array = medioPagos;
        }
      } else {
        array = medioPagos.filter(
          (value) => value.nombre !== "Cuenta Corriente",
        );
      }
    }
    return array;
  };

  const changeMedioPago = (event) => {
    setSelectedMedioPago(event.target.value);

    setMediosPagosChange({
      medioPrevious: medioPagoDefault(),
      medioNew: event.target.value,
    });
  };

  const medioPagoDefault = () => {
    if (pathname === "/presupuesto") return null;

    const medio = medioPagos.filter((x) => x.idMedioPago === medio_pago);
    return medio[0] ? medio[0].nombre : null;
  };

  const handleClienteChangeMedioPago = (nombreMedioPago) => {
    setMediosPagosChange((prev) => ({
      medioPrevious: (prev && prev.medioNew) || medioPagoDefault(),
      medioNew: nombreMedioPago,
    }));
    setSelectedMedioPago(nombreMedioPago);
  };

  /**Funcion que guarda el objeto del medio de pago en redux, segun el nombre del comprobante recibido por el evento */
  const handleChangeMedioPago = () => {
    let comprobanteObjectIdx = medioPagos.findIndex(
      (value) => value.nombre === mediosPagosChange.medioNew,
      // const handleChangeMedioPago = (event) => {
      //   let nombreMedioPago = event.target.value;
      //   let comprobanteObjectIdx = medioPagos.findIndex(
      //     (value) => value.nombre === nombreMedioPago
    );
    setErrorCheque({ ...errorCheque, monto: "", nroCheque: "" });
    setErrorTransferencia({ ...errorTransferencia, fecha: "" });
    dispatch(
      updateMedioPago(
        medioPagos[comprobanteObjectIdx],
        montos,
        medioPago,
        null,
        1,
        isMultipleMedioPago,
      ),
    );
    if (isVenta && cliente && cliente.clienteDescuento.isClienteDescuento) {
      if (mediosPagosChange.medioNew === "Cuenta Corriente") {
        //Calcula los recargos, setea descuento null, calcula subtotal, descuento y total
        dispatch(
          updatePriceChangeMedio(
            cliente.clienteDescuento,
            detalles,
            medioPago,
            true,
          ),
        );
      } else {
        if (mediosPagosChange.medioPrevious === "Cuenta Corriente") {
          //Elimina los recargos de precios, setea descuento null, calcula subtotal, descuento y total
          dispatch(
            updatePriceChangeMedio(
              cliente.clienteDescuento,
              detalles,
              medioPago,
              false,
            ),
          );
        }
      }
    }
  };

  /**Funcion que guarda el objeto del descuento en redux, segun el id del comprobante recibido por el evento */
  const hanldeChangeDescuento = (event) => {
    let idDescuento = event.target.value;
    let descuentoIdx = descuentos.findIndex(
      (value) => value.id_descuento === idDescuento,
    );
    dispatch(
      updateDescuento(descuentos[descuentoIdx] || null, montos, medioPago),
    );
  };

  /**Funcion que guarda el objeto del comprobante en redux, segun el nombre del comprobante recibido por el evento */
  const handleChangeComprobante = (event) => {
    let nombreComprobante = event.target.value;
    let comprobanteObjectIdx = comprobantes.findIndex(
      (value) => value.nombre === nombreComprobante,
    );
    dispatch(updateComprobante(comprobantes[comprobanteObjectIdx]));
    // Si el tipo de comprobante es A se setean a true
    if (nombreComprobante === "A") {
      let impuestos = presupuesto.impuestos.map((imp) => ({
        ...imp,
        seleccionado: imp.aplica,
      }));
      dispatch(updateListaImpuestos(impuestos));
    }
  };

  const changeComprobanteSelect = async () => {
    let newState = await getEstadoCliente(idPresupuesto, cliente);
    let nombreComprobante = "";
    let isFacM = false;
    let selectComprobantes = filtradoComprobantes();
    let valorDefault = selectComprobantes[0].nombre;
    await isFacturaM().then((x) => (isFacM = x));

    if (
      comprobantes.length === 1 ||
      !cliente ||
      cliente.cliente.comprobantePorDefecto === "INTERNO"
    ) {
      if (
        !cliente &&
        comprobantes.length > 1 &&
        selectComprobantes.length > 1
      ) {
        //Se debe buscar que tipo de comprobante esta por defecto
        if (configGeneral.type_receipt === 1) {
          //Se debe setear factura
          nombreComprobante =
            estadoTributarioVendedor !== "EX" &&
            estadoTributarioVendedor !== "MO"
              ? "B"
              : "C";
        } else {
          nombreComprobante = valorDefault;
        }
      } else {
        nombreComprobante = valorDefault;
      }
    } else if (newState.estado === "RI" || newState.estado === "MO") {
      if (configGeneral.type_receipt === 1) {
        if (
          estadoTributarioVendedor !== "EX" &&
          estadoTributarioVendedor !== "MO"
        ) {
          nombreComprobante = !isFacM ? "A" : "M";
        } else {
          nombreComprobante = "C";
        }
      } else if (cliente.cliente.comprobantePorDefecto === "AFIP") {
        nombreComprobante = valorDefault;
      } else {
        nombreComprobante = "Comprobante interno";
      }
    } else if (
      newState.estado === "CF" ||
      newState.estado === "EX" ||
      !newState.estado
    ) {
      if (
        configGeneral.type_receipt === 1 &&
        estadoTributarioVendedor !== "EX" &&
        estadoTributarioVendedor !== "MO"
      )
        nombreComprobante = "B";
      else if (
        estadoTributarioVendedor === "EX" ||
        estadoTributarioVendedor === "MO"
      ) {
        nombreComprobante = "C";
      } else if (cliente.cliente.comprobantePorDefecto === "AFIP") {
        nombreComprobante = valorDefault;
      } else {
        nombreComprobante = "Comprobante interno";
      }
    } else if (newState.estado === "S/E") {
      if (cliente.cliente.comprobantePorDefecto === "AFIP") {
        nombreComprobante = valorDefault;
      } else {
        nombreComprobante = "Comprobante interno";
      }
    } else if (
      estadoTributarioVendedor === "EX" ||
      estadoTributarioVendedor === "MO"
    ) {
      nombreComprobante = "C";
    }
    let idx = comprobantes.findIndex(
      (value) => value.nombre === nombreComprobante,
    );
    dispatch(updateComprobante(comprobantes[idx]));
  };

  //Función que setea el monto del medio a 0 para mostrarlo en el modal y abre el modal
  const updateMontoMedioPago = () => {
    if (detalles.length === 0) {
      errorNotification("El carrito no puede estar vacío");
    } else {
      let zeroPrice = false;
      detalles.forEach((item) => {
        if (item.precio === 0) {
          zeroPrice = true;
        }
      });
      if (zeroPrice) {
        errorNotification("Artículos con precio igual a cero en el carrito");
      } else {
        if (!tipoComprobante || !tipoComprobante.idTipoComprobante) {
          errorNotification("Seleccione el tipo de comprobante para la venta");
        } else {
          if (!presupuesto.provincia) {
            errorNotification("Seleccione una provincia para la venta");
          } else {
            if (!medioPago[0] || !medioPago[0].idMedioPago) {
              errorNotification("Seleccione un medio de pago para la venta");
            } else {
              if (
                medioPago[0].nombre === "Cheque" &&
                (!cliente ||
                  (cliente && cliente.clienteDescuento.isClienteDescuento))
              ) {
                warningNotification(
                  "Elija otro medio de pago para pagos múltiples",
                );
              } else {
                medioPago[0].nombre === "Transferencia Bancaria" &&
                  errorTransferencia.fecha !== "" &&
                  setErrorTransferencia({ ...errorTransferencia, fecha: "" });
                //Si es tarjeta debe cambiar el monto del medio al subtotal - descuento para que no tome al monto con los intereses
                (medioPago[0].nombre === "Tarjeta Crédito" ||
                  medioPago[0].nombre === "Tarjeta Débito") &&
                  dispatch(
                    updateMontoMedio(
                      Number(
                        (
                          Number(montos.subtotal.toFixed(2)) -
                          Number(montos.descuentoMonto.toFixed(2))
                        ).toFixed(2),
                      ),
                      1,
                      medioPago,
                      montos,
                      true,
                    ),
                  );
                if (
                  cliente === null &&
                  tipoComprobante.idTipoFactura !== null &&
                  montos.montoTotal >= montoMaximo &&
                  isVenta
                ) {
                  setModalExcesoMontoCliente(true);
                } else {
                  dispatch(updateMultipleMedioPago(true));
                  setModalPagosMul(true);
                }
              }
            }
          }
        }
      }
    }
  };

  const puedeFacturarFcem =
    presupuesto &&
    Number(presupuesto.montos.montoTotal) >= montoMaximo &&
    cliente &&
    cliente.estadoTributario !== "Monotributista";

  const handlePostVentaPresup = () => {
    if (
      puedeFacturarFcem &&
      (cliente.cliente.CUIT === "" || cliente.cliente.CUIT === null) &&
      (cliente.cliente.numeroDocumento === "" ||
        cliente.cliente.numeroDocumento === null)
    ) {
      setModalErrorClienteCAE(!modalErrorClienteCAE);
    } else {
      setModalPagosMul(false);
      setOpenModalVenta(true);
    }
  };

  const actionsClienteIncompleto = (open) => {
    setOpenModalVenta(false);
    useEmpleado.empleado &&
      localStorage.setItem(
        "responsable_venta",
        useEmpleado.empleado.idEmpleado,
      );
    useQueryEmpleado.setQuery("");
    dispatch(clearPresupuesto(configGeneral, false));
    setModalClienteIncompleto(open);
  };

  const handlePostVenta = async (
    observacion,
    isTicket,
    validarCae,
    isAhorroPapel,
    isEmail,
    correoCliente,
    formatVenta,
    isRemito,
    retiro,
    obsOculta,
    transporteSelected,
  ) => {
    setOpenBackdrop(true);
    try {
      const ID_LEYENDA_VENTA = configGeneral.json_leyendas
        ? configGeneral.json_leyendas.Venta
        : "";
      let newData = valuesPostVenta(
        presupuesto,
        observacion,
        useEmpleado.empleado,
        tipoVentaSelected,
        selectedDate,
        tipoComprobante,
        ID_LEYENDA_VENTA,
        retiro,
        obsOculta,
        transporteSelected,
      );
      if (isVenta) {
        newData.id_remito = idRemito ? idRemito : null;
      }
      let data = {
        ...newData,
        validar: validarCae,
        medioPago: mediosPagoProcesadosConMontosEImpuestos.map((imp) => ({
          ...imp,
          monto: imp.totalConIvaEImpuestos,
        })),
        montoTotal: montoTotalConIvaEImpuestos,
        impuestos: totalesPorImpuesto,
        comision: calcularComision(
          montoTotalConIvaEImpuestos,
          presupuesto.cliente,
        ),
        data_fcem:
          newData.montoTotal >= 546737 &&
          newData.tipoComprobante.nombre === "FCEM A"
            ? {
                fecha_vencimiento_pago:
                  moment(vencimientoDelPago).format("YYYY-MM-DD"),
                alias: alias,
                cbu: cbu,
                opcion_transferencia: opcionTransferencia,
              }
            : null,
      };

      const response = await request({
        method: "POST",
        url: postVenta,
        data: data,
      });
      handleResponsePost(
        response,
        isTicket,
        isAhorroPapel,
        isEmail,
        correoCliente,
        formatVenta,
        isRemito,
      );
    } catch (error) {
      console.log(error);
      setLoading(false);
      setOpenBackdrop(false);
      let msgError = JSON.stringify(error.response.data);
      if (msgError.includes("Actualización de Factura de Boxer fallida")) {
        actionsErrorVenta(false);
        successNotification(
          "Venta generada y facturada con éxito, pero no se pudo actualizar la factura en Boxer. Comuníquese con soporte.",
          6000,
        );
      } else if (msgError.includes("Error al registrar la orden de MELI")) {
        actionsErrorVenta(false);
        errorNotification(
          `Venta generada y facturada con éxito pero ocurrió un error al registrar la orden de Mercado Libre.`,
          6000,
        );
      } else if (msgError.includes("Error al validar la factura")) {
        const expresionRegular = /Id venta (\d+)/;

        const resultado = msgError.match(expresionRegular);
        let idVentaError =
          resultado && resultado.length > 1 ? resultado[1] : null;
        setIdVentaErrorAFIP(idVentaError);
        actionsErrorVenta(true);
        errorNotification(
          "Venta generada con éxito pero no se pudo validar la factura y está pendiente de CAE.",
          6000,
        );
      } else if (msgError.includes("Error afip")) {
        errorNotification(
          `ERROR DE AFIP: ${Object.values(error.response.data)}`,
          6000,
        );
      } else if (msgError.includes("Error. El cliente esta incompleto")) {
        const expresionRegular = /Id venta (\d+)/;

        const resultado = msgError.match(expresionRegular);
        let idVentaError =
          resultado && resultado.length > 1 ? resultado[1] : null;
        setIdVentaErrorAFIP(idVentaError);
        actionsClienteIncompleto(true);
        errorNotification(
          "Venta generada con éxito pero no se pudo validar la factura y está pendiente de CAE.",
          6000,
        );
      } else {
        errorNotification("No se puede registrar la factura.");
      }
    }
  };

  const actionsErrorVenta = (open) => {
    setOpenModalVenta(false);
    useEmpleado.empleado &&
      localStorage.setItem(
        "responsable_venta",
        useEmpleado.empleado.idEmpleado,
      );
    useQueryEmpleado.setQuery("");
    dispatch(clearPresupuesto(configGeneral, false));
    dispatch(updateMultipleMedioPago(false));
    setModalErrorAFIP(open);
  };

  const handleResponsePost = (
    response,
    isTicket,
    isAhorroPapel,
    isEmail,
    correoCliente,
    formatVenta,
    isRemito,
  ) => {
    if (response.status === 201) {
      setOpenModalVenta(false);
      setLoading(false);
      useEmpleado.empleado &&
        localStorage.setItem(
          "responsable_venta",
          useEmpleado.empleado.idEmpleado,
        );
      setOpenBackdrop(false);
      successNotification("Venta registrada con éxito");
      useQueryEmpleado.setQuery("");
      if (formatVenta) {
        isEmail
          ? sendMail(response.data.idVenta, correoCliente)
          : printInvoice(
              isTicket,
              response.data.idVenta,
              isAhorroPapel,
              isRemito,
              formatVenta,
            );
      }
      localStorage.setItem("formatoComprobante", formatVenta);
      dispatch(clearPresupuesto(configGeneral, false));
      dispatch(updateMultipleMedioPago(false));
    }
  };

  const printInvoice = (
    isTicket,
    idVenta,
    isAhorroPapel,
    isRemito,
    formatVenta,
  ) => {
    setOpenBackdrop(true);
    getVenta(idVenta).then((res) => {
      let newData = articlesPDF.getArticlesPDF(
        res.data.data,
        configGeneral,
        isTicket ? "ventaTicket" : isAhorroPapel ? "ventaA4Ahorro" : "ventaA4",
        isRemito,
        null,
      );
      if (configGeneral.previsualizacion) {
        if (isTicket) {
          generatePdf("ventaTicket", newData, isRemito);
        } else if (formatVenta === "WPA4") {
          handleOpenModalPdf(newData);
          setOpenBackdrop(true);
        } else {
          generatePdf(
            isAhorroPapel ? "ventaA4Ahorro" : "ventaA4",
            newData,
            isRemito,
          );
        }
      } else {
        if (isTicket) {
          generatePdfConPrevisualizacion("ventaTicket", newData, isRemito);
        } else if (formatVenta === "WPA4") {
          handleOpenModalPdf(newData);
          setOpenBackdrop(true);
        } else {
          generatePdfConPrevisualizacion(
            isAhorroPapel ? "ventaA4Ahorro" : "ventaA4",
            newData,
            isRemito,
          );
        }
      }
      setOpenBackdrop(false);
    });
  };

  const handleOpenModalPdf = (newData) => {
    dataWP.setDataPdfFetched(newData);
  };

  const sendMail = async (idVenta, correoCliente) => {
    try {
      const response = await request({
        method: "GET",
        url: sendMailVenta(idVenta),
        params: { mail: correoCliente },
      });
      if (response.status === 201 || response.status === 200) {
        successNotification(
          "Email enviado con éxito. Puede avisar que se revise la bandeja de entrada.",
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetOpcionesTransferencia = async () => {
    try {
      const response = await request({
        method: "GET",
        url: "/api/facturacion/opciones-transferencia/",
      });
      setOpcionesTransferencia(response.data);
      setOpcionTransferencia(response.data[0].nombre);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateChange = (event) => {
    const inputDate = new Date(event.target.value);
    setVencimientoDelPago(inputDate);
  };

  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  /**
   * Valida si debe o no mostrarse la alerta por recrear una venta
   * y tener multiples medios de pagos.
   *
   * Si se recrea una venta y esta tenia multiples medios de pagos retorna `true`
   * @returns bool
   */
  const mostrarAlertaReCrearVentaMultiplesMediosPago = () => {
    let isPathReCrearVenta = pathname.includes("/ventas/re-venta/");
    return (
      isVenta &&
      isPathReCrearVenta &&
      presupuesto.recrear_venta_multiple_medios_pagos
    );
  };

  /**
   * Valida si debe o no mostrarse el alerta por recrear una venta o presupuesto
   * que tiene un medio de pago con tarjeta (credito o debito) y no tiene coeficiente
   *
   * Si se recrea una venta o un presupuesto y esta tenia un medio de pago
   * con tarjeta (credito o debito) y no tiene coeficiente retorna `true`
   *
   * @returns bool
   */
  const mostrarAlertaReCrearVentaMedioPagoTarjetaSinCoeficiente = () => {
    return presupuesto.recrear_venta_medio_pago_tarjeta_sin_coeficiente;
  };

  /**
   * Valida si debe o no mostrarse el alerta por recrear una venta o presupuesto
   * que tiene un medio de pago con tarjeta (credito o debito) y el coeficiente
   * seleccionado sufrió cambios
   *
   * Si se recrea una venta o un presupuesto y esta tenia un medio de pago
   * con tarjeta (credito o debito) y el coeficiente
   * seleccionado sufrió cambios
   *
   * @returns bool
   */
  const mostrarAlertaReCrearVentaMedioPagoTarjetaConCambios = () => {
    return presupuesto.recrear_venta_medio_pago_tarjeta_con_cambios;
  };

  useEffect(() => {
    handleGetComprobantes();
    handleGetMedioPago();
    handleGetDescuentos();
  }, []);

  useEffect(() => {
    if (comprobantes.length !== 0) {
      changeComprobanteSelect();
    }
    if (cliente === null) {
      setSelectedMedioPago(medioPagoDefault());
    }
    if (cliente && cliente.id_medio_pago && cliente.id_medio_pago.nombre) {
      handleClienteChangeMedioPago(cliente.id_medio_pago.nombre);
    } else {
      setSelectedMedioPago(medioPagoDefault());
    }
  }, [cliente]);

  useEffect(() => {
    if (descuentos.length !== 0 && cliente) {
      if (isVenta) {
        let isChangePrice =
          !cliente.clienteDescuento.isClienteDescuento &&
          !cliente.clienteDescuento.clientePrevious
            ? false
            : true;
        let isCtaCte =
          medioPago[0] &&
          medioPago[0].nombre &&
          medioPago[0].nombre === "Cuenta Corriente"
            ? true
            : false;
        if (!isChangePrice || (isChangePrice && !isCtaCte)) {
          if (cliente.descuento_prefijado !== null) {
            let descuentoIdx = descuentos.findIndex(
              (value) =>
                value.id_descuento === cliente.descuento_prefijado.id_descuento,
            );
            dispatch(
              updateDescuento(
                descuentos[descuentoIdx] || null,
                montos,
                medioPago,
              ),
            );
          }
        }
      } else {
        if (cliente.descuento_prefijado !== null) {
          let descuentoIdx = descuentos.findIndex(
            (value) =>
              value.id_descuento === cliente.descuento_prefijado.id_descuento,
          );
          dispatch(
            updateDescuento(
              descuentos[descuentoIdx] || null,
              montos,
              medioPago,
            ),
          );
        }
      }
    } else {
      dispatch(updateDescuento(null, montos, medioPago));
    }
  }, [cliente, descuentos]);

  useEffect(() => {
    if (medioPagos.length !== 0) {
      setSelectedMedioPago(medioPagoDefault());
      if (!idPresupuesto && medioPago.length === 0) {
        let exist = medioPagos.findIndex(
          (x) => x.idMedioPago === configGeneral.medio_pago,
        );
        dispatch(
          addNewMedio(
            medioPagos[exist],
            montos,
            medioPago,
            null,
            1,
            isMultipleMedioPago,
            true,
          ),
        );
      }
    }
  }, [medioPagos]);

  useEffect(() => {
    if (isVenta) {
      if (dataWP.urlPdf) {
        dataWP.setOpenModalWhatsapp(true);
      } else if (dataWP.dataPdfFetched) {
        getPdfBlob("ventaA4", dataWP.dataPdfFetched, configGeneral).then(
          (res) => {
            if (res) {
              dataWP.setUrlPdf(res.secure_url);
            }
            setOpenBackdrop(false);
          },
        );
        dataWP.setDataPdfFetched(null);
      }
    }
  }, [dataWP.urlPdf, dataWP.dataPdfFetched]);

  useEffect(() => {
    if (medioPago.length > 0) {
      if (
        !cliente &&
        (medioPago[0].nombre === "Cuenta Corriente" ||
          medioPago[0].nombre === "Cheque") &&
        !idOrden
      ) {
        let comprobanteObjectIdx = medioPagos.findIndex(
          (value) => value.nombre === "Efectivo",
        );
        dispatch(
          updateMedioPago(
            medioPagos[comprobanteObjectIdx],
            montos,
            medioPago,
            null,
            1,
            isMultipleMedioPago,
          ),
        );
      }
      if (idOrden) {
        if (
          presupuesto.dataOrdenMeli &&
          (presupuesto.dataOrdenMeli[0].payments[0].payment_type ===
            "credit_card" ||
            presupuesto.dataOrdenMeli[0].payments[0].payment_type ===
              "account_money" ||
            presupuesto.dataOrdenMeli[0].payments[0].payment_type ===
              "ticket" ||
            presupuesto.dataOrdenMeli[0].payments[0].payment_type ===
              "digital_currency")
        ) {
          let comprobanteObjectIdx = medioPagos.findIndex(
            (value) => value.nombre === "Tarjeta Crédito",
          );
          dispatch(
            updateMedioPago(
              medioPagos[comprobanteObjectIdx],
              montos,
              medioPago,
              null,
              1,
              isMultipleMedioPago,
            ),
          );
        } else if (
          presupuesto.dataOrdenMeli &&
          presupuesto.dataOrdenMeli[0].payments[0].payment_type === "debit_card"
        ) {
          let comprobanteObjectIdx = medioPagos.findIndex(
            (value) => value.nombre === "Tarjeta Débito",
          );
          dispatch(
            updateMedioPago(
              medioPagos[comprobanteObjectIdx],
              montos,
              medioPago,
              null,
              1,
              isMultipleMedioPago,
            ),
          );
        } else if (
          presupuesto.dataOrdenMeli &&
          presupuesto.dataOrdenMeli[0].payments[0].payment_type === "debit_card"
        ) {
          let comprobanteObjectIdx = medioPagos.findIndex(
            (value) => value.nombre === "Tarjeta Débito",
          );
          dispatch(
            updateMedioPago(
              medioPagos[comprobanteObjectIdx],
              montos,
              medioPago,
              null,
              1,
              isMultipleMedioPago,
            ),
          );
        } else if (
          presupuesto.dataOrdenMeli &&
          presupuesto.dataOrdenMeli[0].payments[0].payment_type ===
            "bank_transfer"
        ) {
          let comprobanteObjectIdx = medioPagos.findIndex(
            (value) => value.nombre === "Transferencia Bancaria",
          );
          dispatch(
            updateMedioPago(
              medioPagos[comprobanteObjectIdx],
              montos,
              medioPago,
              null,
              1,
              isMultipleMedioPago,
            ),
          );
        } else {
          let comprobanteObjectIdx = medioPago.findIndex(
            (value) => value.nombre === "Tarjeta Crédito",
          );
          dispatch(
            updateMedioPago(
              medioPagos[comprobanteObjectIdx],
              montos,
              medioPago,
              null,
              1,
              isMultipleMedioPago,
            ),
          );
        }
      }
    }
  }, [cliente, presupuesto.dataOrdenMeli, idOrden, medioPagos]);

  useEffect(() => {
    if (errorCheque.monto !== "") {
      setErrorCheque({ ...errorCheque, monto: "" });
    }
  }, [montos.montoTotal]);

  useEffect(() => {
    if (mediosPagosChange) {
      handleChangeMedioPago();
    }
  }, [mediosPagosChange]);

  useEffect(() => {
    handleGetOpcionesTransferencia();
  }, []);

  useEffect(() => {
    if (opcionesTransferencia.length) {
      setOpcionTransferencia(opcionesTransferencia[0].id);
    }
  }, [opcionesTransferencia]);

  useEffect(() => {
    // Este useEffect setea el medio de pago siempre que no exista idPresupuesto e idVenta, el tamaño de medioPago sea mayor a cero y dependiendo los siguientes casos:
    // 1. Se ingresa a la venta
    //  a. Si existe un medio de pago, no se hace nada ya que debe permanecer el medio selecionado.
    //  b. Si no existe medio de pago y existe cliente entonces busca el medio del mismo de lo contrario setea el de configuracion general o Efectivo
    // 2. Se ingresa a presupuestos
    //  a. Se debe poner como medio de pago uno sin definir (idMedioPago = null)
    let medioPagoSelected = null;

    if (
      !idPresupuesto &&
      !idVenta &&
      medioPago.length > 0 &&
      medioPagos.length > 0
    ) {
      if (isVenta) {
        if (!medioPago[0].idMedioPago) {
          if (cliente && cliente.id_medio_pago) {
            medioPagoSelected = medioPagos.filter(
              (value) =>
                value.idMedioPago === cliente.id_medio_pago.idMedioPago,
            )[0];
          } else {
            if (configGeneral && configGeneral.medio_pago) {
              medioPagoSelected = medioPagos.filter(
                (x) => x.idMedioPago === configGeneral.medio_pago,
              )[0];
            } else {
              medioPagoSelected = medioPagos.filter(
                (value) => value.nombre === "Efectivo",
              )[0];
            }
          }
        }
      } else {
        medioPagoSelected = medioPagoSinDefinir();
      }
      medioPagoSelected &&
        dispatch(
          updateMedioPago(
            medioPagoSelected,
            montos,
            medioPago,
            null,
            1,
            isMultipleMedioPago,
          ),
        );

      if (
        isVenta &&
        medioPagoSelected &&
        medioPagoSelected.nombre === "Cuenta Corriente" &&
        cliente &&
        cliente.clienteDescuento.isClienteDescuento
      ) {
        //Calcula los recargos, setea descuento null, calcula subtotal, descuento y total
        dispatch(
          updatePriceChangeMedio(
            cliente.clienteDescuento,
            detalles,
            medioPago,
            true,
          ),
        );
      }
    }
  }, [medioPagos]);

  const setMedioPagoSinDefinir = () => {
    dispatch(
      updateMedioPago(
        medioPagoSinDefinir(),
        montos,
        medioPago,
        null,
        1,
        isMultipleMedioPago,
      ),
    );
    setSelectedMedioPago(null);
  };

  return (
    <React.Fragment>
      <Card className={isVenta ? "" : "card-gray"} style={{ minHeight: 200 }}>
        <CardTitle
          className="text-center divder-card-gray"
          style={{ fontSize: isVenta ? "21px" : "19px", fontWeight: "bold" }}
        >
          FACTURACION
        </CardTitle>
        <CardBody className="p-0 pb-2">
          <Container>
            <Row className="">
              {
                // isVenta &&
                configCCC &&
                  configCCC.dataConfig.mensaje !== "" &&
                  configCCC.dataConfig.bloquearCtaCte && (
                    <Col xs={12} className="pb-2">
                      <Alert
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        severity={"error"}
                      >
                        <strong>{configCCC.dataConfig.mensaje}</strong>
                      </Alert>
                    </Col>
                  )
              }
              {mostrarAlertaReCrearVentaMedioPagoTarjetaConCambios() && (
                <Col xs={12} className="pb-2 mb-2">
                  <Alert
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    severity={"info"}
                  >
                    {`El monto final ${idPresupuesto ? "del presupuesto" : "de la venta"} puede variar, debido a que el
                    coeficiente de la tarjeta sufrió cambios desde la creación
                    ${idPresupuesto ? "del presupuesto" : "de la venta"} al día de hoy.`}
                  </Alert>
                </Col>
              )}
              {mostrarAlertaReCrearVentaMedioPagoTarjetaSinCoeficiente() && (
                <Col xs={12} className="pb-2 mb-2">
                  <Alert
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    severity={"info"}
                  >
                    {`No pudimos obtener el medio de pago tarjeta ${idPresupuesto ? "del presupuesto" : "de la venta"} que
                    quieres recrear`}
                    <br /> Por favor, cárgalo nuevamente.
                  </Alert>
                </Col>
              )}
              {mostrarAlertaReCrearVentaMultiplesMediosPago() && (
                <Col xs={12} className="pb-2 mb-2">
                  <Alert
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    severity={"info"}
                  >
                    No pudimos obtener los medios de pagos de la venta que
                    quieres recrear
                    <br /> Por favor, cárgalos nuevamente.
                  </Alert>
                </Col>
              )}
              <Col
                xs={multiplePago ? (!isClienteDescuento ? "5" : "6") : "6"}
                className={
                  multiplePago ? (!isClienteDescuento ? "pr-1" : "") : ""
                }
              >
                <TextField
                  style={{ backgroundColor: "white" }}
                  id="comprobante"
                  select
                  fullWidth
                  size="small"
                  label="Comprobante"
                  value={tipoComprobante ? tipoComprobante.nombre : ""}
                  onChange={handleChangeComprobante}
                  variant="outlined"
                  disabled={
                    cliente &&
                    cliente.cliente &&
                    cliente.cliente.comprobantePorDefecto === "INTERNO"
                  }
                >
                  {filtradoComprobantes().map((option, index) => (
                    <MenuItem
                      key={index}
                      index={index}
                      value={option.nombre}
                      name={option.nombre}
                    >
                      {option.nombre}
                    </MenuItem>
                  ))}
                </TextField>
              </Col>
              <Col
                xs={
                  multiplePago
                    ? !isClienteDescuento
                      ? "5"
                      : "6"
                    : isPresupuesto &&
                        medioPago.length > 0 &&
                        medioPago[0].idMedioPago
                      ? "5"
                      : "6"
                }
                className={
                  multiplePago
                    ? !isClienteDescuento
                      ? "pr-1"
                      : ""
                    : isPresupuesto &&
                        medioPago.length > 0 &&
                        medioPago[0].idMedioPago
                      ? "pr-1"
                      : ""
                }
              >
                <TextField
                  style={{ backgroundColor: "white" }}
                  id="medio_pago"
                  select
                  fullWidth
                  size="small"
                  disabled={idOrden}
                  label="Medio de pago"
                  value={selectedMedioPago}
                  onChange={changeMedioPago}
                  variant="outlined"
                >
                  {filtradoMediosPagos().map((option) => (
                    <MenuItem
                      key={option.idMedioPago}
                      value={option.nombre}
                      name={option.nombre}
                    >
                      {option.nombre}
                    </MenuItem>
                  ))}
                </TextField>
              </Col>
              {isPresupuesto &&
                medioPago.length > 0 &&
                medioPago[0].idMedioPago && (
                  <Col xs="1" style={{ paddingTop: 8 }}>
                    <TooltipWithoutIcon
                      body={
                        <h6
                          style={{
                            fontSize: 15,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          Eliminar medio de pago
                        </h6>
                      }
                      placement={"top"}
                    >
                      <DeleteIcon
                        style={{ color: "#c4183c", cursor: "pointer" }}
                        onClick={() => setMedioPagoSinDefinir()}
                      />
                    </TooltipWithoutIcon>
                  </Col>
                )}

              {multiplePago && !isClienteDescuento && !idOrden && (
                <Col xs={2} className="pl-1">
                  <Avatar
                    variant="rounded"
                    title="Pagos múltiples"
                    style={{
                      backgroundColor: "rgb(0, 123, 255)",
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      fontSize: 25,
                      fontWeight: "bold",
                    }}
                    onClick={updateMontoMedioPago}
                  >
                    +
                  </Avatar>
                </Col>
              )}
            </Row>
            {medioPago.length > 0 &&
              !isMultipleMedioPago &&
              medioPago[0].tipo === "Tarjeta" && (
                <Tarjetas
                  idPresupuesto={idPresupuesto}
                  medioSelected={getDataTarjeta(medioPago[0])}
                  idOrden={idOrden}
                  datosOrdenMeli={datosOrdenMeli}
                >
                  <FormAutorizacionTarjeta
                    medioPago={medioPago}
                    montos={montos}
                  />
                </Tarjetas>
              )}

            {medioPago.length > 0 &&
              medioPago[0].tipo === "Transferencia" &&
              isVenta && (
                <TransferenciaBancaria
                  medioSelected={medioPago[0]}
                  isMultipleMedio={false}
                  errorTransferencia={errorTransferencia}
                  setErrorTransferencia={setErrorTransferencia}
                />
              )}

            {medioPago.length > 0 &&
              medioPago[0].tipo === "Cheque" &&
              isVenta && (
                <Cheque
                  medioSelected={medioPago[0]}
                  descuentos={descuentos}
                  hanldeChangeDescuento={hanldeChangeDescuento}
                  errorCheque={errorCheque}
                  setErrorCheque={setErrorCheque}
                />
              )}
            {/* En desarrollo */}
            <Row>
              {medioPago.length > 0 &&
                (medioPago[0].tipo !== "Cheque" ||
                  (medioPago[0].tipo === "Cheque" && isPresupuesto)) &&
                !idOrden && (
                  <Col
                    xs={multiplePago ? "5" : "6"}
                    className={multiplePago ? "mt-3 pr-1" : "mt-3"}
                  >
                    <TextField
                      style={{ backgroundColor: "white" }}
                      select
                      fullWidth
                      size="small"
                      label="Descuento"
                      value={descuento ? descuento.id_descuento : ""}
                      onChange={hanldeChangeDescuento}
                      variant="outlined"
                      name="Descuento"
                      disabled={
                        isVenta &&
                        cliente &&
                        cliente.clienteDescuento.isClienteDescuento &&
                        medioPago[0].nombre === "Cuenta Corriente"
                          ? true
                          : false
                      }
                    >
                      <MenuItem selected value="">
                        Sin descuento
                      </MenuItem>
                      {descuentos.map((option) => (
                        <MenuItem
                          key={option.id_descuento}
                          value={option.id_descuento}
                          name={option.nombre}
                        >
                          {option.nombre}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Col>
                )}

              {multiplePago &&
              presupuesto &&
              presupuesto.tipoComprobante &&
              presupuesto.tipoComprobante.nombre === "FCEM A" ? (
                <Col xs={"7"} className={"mt-3"}>
                  <TextField
                    style={{ backgroundColor: "white" }}
                    select
                    fullWidth
                    size="small"
                    label="Opción Transferencia"
                    value={opcionTransferencia}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setOpcionTransferencia(e.target.value)}
                    variant="outlined"
                  >
                    {opcionesTransferencia.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                        name={option.nombre}
                      >
                        {option.nombre}
                      </MenuItem>
                    ))}
                  </TextField>
                </Col>
              ) : null}
            </Row>

            {multiplePago &&
            presupuesto &&
            presupuesto.tipoComprobante &&
            presupuesto.tipoComprobante.nombre === "FCEM A" ? (
              <Row>
                <Col
                  xs={multiplePago ? "5" : "6"}
                  className={multiplePago ? "mt-3 pr-1" : "mt-3"}
                >
                  <TextField
                    style={{ backgroundColor: "white" }}
                    fullWidth
                    size="small"
                    label="CBU"
                    defaultValue={configGeneral.cbu}
                    disabled
                    value={cbu}
                    onChange={(e) => setCbu(e.target.value)}
                    variant="outlined"
                    name="fechavencimiento"
                  />
                </Col>
                <Col xs={multiplePago ? "7" : "6"} className={"mt-3"}>
                  <TextField
                    style={{ backgroundColor: "white" }}
                    fullWidth
                    size="small"
                    label="Alias"
                    defaultValue={configGeneral.alias}
                    disabled
                    value={alias}
                    onChange={(e) => setAlias(e.target.value)}
                    variant="outlined"
                    name="fechavencimiento"
                  />
                </Col>
                <Col xs={12} className={"mt-3"}>
                  <TextField
                    id="date"
                    size="small"
                    variant="outlined"
                    label="Vencimiento del pago"
                    type="date"
                    style={{ background: "#ffffff", width: "100%" }}
                    value={formatDate(vencimientoDelPago)}
                    onChange={(e) => handleDateChange(e)}
                    defaultValue={formatDate(vencimientoDelPago)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: formatDate(
                        new Date(new Date().setDate(new Date().getDate() - 5)),
                      ),
                    }}
                  />
                </Col>
              </Row>
            ) : null}
            {Boolean(userData.monto_minimo_fcem) && (
              <Row>
                <Col
                  xs={12}
                  className="mt-3"
                  style={{ alignContent: "center" }}
                >
                  <IconButton
                    style={{ marginRight: "8px" }}
                    size="small"
                    onClick={toggleModalFCEM}
                  >
                    <SvgIcon fontSize="small">
                      <HelpIcon />
                    </SvgIcon>
                  </IconButton>
                  Monto mínimo FCEM:{" "}
                  {formatoMoneda({ value: userData.monto_minimo_fcem })}
                </Col>
              </Row>
            )}
          </Container>
        </CardBody>
        <Backdrop
          className={classes.backdrop}
          open={
            loadingComprobante ||
            loadingDescuento ||
            loadingMedioPago ||
            loadingPresupuesto
          }
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Card>

      {modalPagosMul && (
        <ModalPagosMultiples
          open={modalPagosMul}
          handleClose={() => {
            setModalPagosMul(false);
            dispatch(updateMultipleMedioPago(false));
            dispatch(deleteMedioPago(medioPago, montos, null, false));
          }}
          tiposMedios={medioPagos}
          idPresupuesto={idPresupuesto}
          handlePostVentaPresup={handlePostVentaPresup}
          configCCC={configCCC}
        />
      )}

      {openModalVenta && (
        <ModalVenta
          montoTotal={montoTotalConIvaEImpuestos}
          open={openModalVenta}
          toggle={() => {
            useQueryEmpleado.setQuery("");
            setOpenModalVenta(false);
            dispatch(updateMultipleMedioPago(false));
            dispatch(deleteMedioPago(medioPago, montos, null, false));
          }}
          isMultipleMedio={true}
          realizarVenta={(
            observacion,
            isTicket,
            validarCae,
            isAhorroPapel,
            isEmail,
            correoCliente,
            formatVenta,
            isRemito,
            retiro,
            obsOculta,
            transporteSelected,
          ) =>
            handlePostVenta(
              observacion,
              isTicket,
              validarCae,
              isAhorroPapel,
              isEmail,
              correoCliente,
              formatVenta,
              isRemito,
              retiro,
              obsOculta,
              transporteSelected,
            )
          }
          openBackdrop={openBackdrop}
          loading={loading}
          setLoading={setLoading}
          // setTiposVenta={setTiposVenta}
          tipoVentaSelected={tipoVentaSelected}
          setTipoVentaSelected={setTipoVentaSelected}
          tiposVenta={tiposVenta}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          dataEmpleado={{ useEmpleado, useQueryEmpleado }}
          configCCC={configCCC}
        />
      )}

      {modalExcesoMontoCliente && (
        <ModalClienteMontoExcedido
          open={modalExcesoMontoCliente}
          montoMaximo={montoMaximo}
          handleClose={() => setModalExcesoMontoCliente(false)}
          confirm={() => {
            dispatch(updateMultipleMedioPago(true));
            setModalPagosMul(true);
          }}
        />
      )}

      {openModalFCEM && (
        <ModalInformacionFCEM
          open={openModalFCEM}
          handleClose={toggleModalFCEM}
        />
      )}

      {modalErrorClienteCAE && presupuesto && (
        <ModalValidacionClientCAE
          idCliente={cliente.idClienteDeSucursal}
          open={modalErrorClienteCAE}
          handleClose={() => setModalErrorClienteCAE(!modalErrorClienteCAE)}
        />
      )}
    </React.Fragment>
  );
}
